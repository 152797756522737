<template>
  <div>
    <div v-if="mainStore.isMobile" class="min-h-screen min-h-dvh">
      <MobileHome v-if="!showCustomMobile"></MobileHome>
      <div v-else class="min-h-screen min-h-dvh flex flex-col">
        <HeaderDefault v-if="mainStore.header"></HeaderDefault>
        <div class="flex-1">
          <slot />
        </div>
        <AppFooter v-if="mainStore.footer"></AppFooter>
        <LayoutCommon></LayoutCommon>
      </div>
    </div>
    <div v-else class="min-h-screen min-h-dvh flex flex-col">
      <HeaderDefault v-if="mainStore.header"></HeaderDefault>
      <div class="flex-1">
        <slot />
      </div>
      <AppFooter v-if="mainStore.footer"></AppFooter>
      <LayoutCommon></LayoutCommon>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useMainStore } from "@/store/index";
const { locale } = useI18n();
const mainStore = useMainStore();
const route = useRoute();
const device = useDevice();
const exclude = ["blog___", "blog-slug___"];
const showCustomMobile = ref(false);
exclude.map((v) => {
  if (route?.name?.includes(v)) {
    showCustomMobile.value = true;
  }
});
console.log("showCustomMobile", showCustomMobile.value);

if (device.isMobile) {
  mainStore.setMobile(true);
}
watch(() => route.name, (n, o) => {
  showCustomMobile.value = false;
  exclude.map((v) => {
    if (n && n.includes(v)) {
      showCustomMobile.value = true;
    }
  });
});
// console.log(parts);
// if (parts[1] == 'blog') {
//   device.isMobile = false
// } else if (parts[2] == 'blog') {
//   device.isMobile = false
// }
// const isMobile = ref(device.isMobile || mainStore.isMobile)
// const { data } = await useAsyncData('deviceType', () => {
//   return { isMobile: device.isMobile || mainStore.isMobile }
// })

useHead({
  htmlAttrs: {
    lang: locale.value,
  },
});
</script>
